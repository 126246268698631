import logo from '../assets/onsight-login.png';
import Container from 'react-bootstrap/Container';

const Header: React.FC = () => {
  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <div className="col-md-3 mb-2 mb-md-0">
          <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none">
            <img src={logo} alt="Logo" width="150px" />
          </a>
        </div>
      </header>
    </Container>
  );
};

export default Header;
