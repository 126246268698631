import Container from 'react-bootstrap/Container';
import CameraView from './camera/camera-view';
import { Device } from '../types/device';

export interface DevicesViewProps {
  devices: Array<Device>;
}

export default function DevicesView({ devices }: DevicesViewProps) {
  return (
    <>
      <Container fluid>
        <div className="row">
          <h3>Live View</h3>
          {devices.map((device) => (
            <>{device.cameras?.map((camera) => <CameraView key={camera.id} camera={camera} />)}</>
          ))}
        </div>
      </Container>
    </>
  );
}
