import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js/core';
import Footer from '../components/footer';
import 'react-phone-number-input/style.css';
import AuthHeader from '../components/site-header';
import { DeviceContext } from '../context/device-context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LeftNav from '../components/left-nav';
import { Api } from '../services/api';
import { SessionContext } from '../context/session-context';

export default function Subscribe() {
  const api = Api.getInstance();
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>();
  const [fetched, setFetched] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const deviceContext = useContext(DeviceContext);
  const sessionContext = useContext(SessionContext);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    // if (passwordRequired === null) {
    //   signInClicked();
    // } else {
    //   updateNewPassword();
    // }
    console.log('Number Submitted', phoneNumber);
    if (phoneNumber === undefined || phoneNumber.trim() === '' || !isValidPhoneNumber(phoneNumber)) {
      setError('Please enter a valid phone number');
    } else {
      const site = sessionContext.details.site;
      const email = sessionContext.details.email;
      try {
        await api.subscribe(site, email, phoneNumber as string);
      } catch (error) {
        setError((error as Error).message);
      }
    }
  };

  const unsubscribe = async () => {
    console.log('Unsubscribe');
    setError(undefined);
    const site = sessionContext.details.site;
    const email = sessionContext.details.email;
    try {
      await api.unsubscribe(site, email);
      setPhoneNumber('' as E164Number);
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const getPhoneNumber = async () => {
    if (fetched === true) {
      return;
    }
    setFetched(true);
    const site = sessionContext.details.site;
    const email = sessionContext.details.email;
    const number = await api.getSubscriptionPhoneNumber(site, email);
    setPhoneNumber(number as E164Number);
  };

  useEffect(() => {
    getPhoneNumber();
  });

  return (
    <>
      <Container as="main" className="py-4 px-3 mx-auto">
        <AuthHeader />
        <Row>
          <Col lg={2}>{deviceContext.devices !== null && <LeftNav devices={deviceContext.devices} />}</Col>
          <Col lg={10}>
            <Container style={{ width: '350px' }} className="align-items-center">
              <p>Use this page to subscribe or unsubscribe for notifications alerts.</p>
              <Form onSubmit={submitForm}>
                <>
                  <Form.Group className="mb-4" controlId="emailGroup">
                    <Form.Label>Mobile Phone Number</Form.Label>
                    <PhoneInput className="rounded-pill" placeholder="Enter phone number" defaultCountry="US" value={phoneNumber} onChange={setPhoneNumber} />
                  </Form.Group>
                  <Alert variant="danger" hidden={error === undefined}>
                    {error}
                  </Alert>
                  <div className="text-center">
                    <Button variant="primary" type="submit" className="rounded-pill" style={{ width: '140px', marginRight: '15px' }}>
                      Subscribe
                    </Button>
                    <Button variant="secondary" type="button" className="rounded-pill" style={{ width: '140px' }} onClick={unsubscribe}>
                      Unsubscribe
                    </Button>
                  </div>
                </>
              </Form>
            </Container>
          </Col>
        </Row>

        <Footer />
      </Container>
    </>
  );
}
