import Container from 'react-bootstrap/Container';
import spinner from '../../assets/spinner.svg';

export default function CameraLoader() {
  return (
    <>
      <Container className="text-center">
        <img className="spinner" src={spinner} alt="loading" />
        <p>Loading camera images</p>
      </Container>
    </>
  );
}
