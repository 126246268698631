import Container from 'react-bootstrap/Container';

const Footer: React.FC = () => {
  return (
    <Container>
      <footer>
        {/* <hr className="mt-5 mb-4" />
                <p className="text-muted">
                    © Copyright 2023 Onsight
                </p> */}
      </footer>
    </Container>
  );
};

export default Footer;
