import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Header from '../components/header';
import Footer from '../components/footer';
import { useValidPassword, useValidUsername } from '../hooks/auth-hooks';
import { SessionContext } from '../context/session-context';
import { NewPasswordRequired } from '../service/cognito-service';
import logo from '../assets/onsight-login.png';

export default function Login() {
  const { username, setUsername, usernameIsValid } = useValidUsername('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordRequired, setPasswordRequired] = useState<NewPasswordRequired | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0;

  const history = useNavigate();

  const sessionContext = useContext(SessionContext);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordRequired === null) {
      signInClicked();
    } else {
      updateNewPassword();
    }
  };

  const signInClicked = async () => {
    try {
      setError('');
      await sessionContext.login(username.toLowerCase(), password);
      history('/');
    } catch (error) {
      if (error instanceof NewPasswordRequired) {
        setPasswordRequired(error as NewPasswordRequired);
      } else {
        console.error('Error', error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error as any)?.code === 'UserNotConfirmedException') {
          history('/verify');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setError((error as any).message);
        }
      }
    }
  };

  const updateNewPassword = async () => {
    if (passwordRequired === null) {
      setError('Unexpected error, please refresh the page.');
      return;
    }
    try {
      await sessionContext.completeNewPasswordChallenge(passwordRequired.user, passwordRequired.requiredAttributes, newPassword);
      history('/');
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message);
    }
  };

  const forgotClicked = () => {
    history('/forgot');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUsername(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewPassword(value);
  };

  return (
    <>
      <Container as="main" className="py-4 px-3 mx-auto">
        <Header />
        <Container style={{ width: '350px' }} className="align-items-center">
          <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none" style={{ paddingBottom: '20px' }}>
            <img src={logo} alt="Logo" width="350px" />
          </a>
          <Form onSubmit={submitForm}>
            {passwordRequired === null && (
              <>
                <Form.Group className="mb-4" controlId="emailGroup">
                  <Form.Label>username</Form.Label>
                  <Form.Control className="rounded-pill" type="email" value={username} onChange={handleEmailChange} required={true} />
                </Form.Group>
                <Form.Group className="mb-4" controlId="passwordGroup">
                  <Form.Label>password</Form.Label>
                  <Form.Control className="rounded-pill" type="password" value={password} onChange={handlePasswordChange} required={true} />
                </Form.Group>
                <Alert variant="danger" hidden={error === ''}>
                  {error}
                </Alert>
                <div className="text-center">
                  <Button variant="primary" type="submit" className="rounded-pill" style={{ width: '140px' }}>
                    login
                  </Button>
                  <Button variant="secondary" type="button" className="rounded-pill" style={{ minWidth: '140px', marginLeft: '10px' }} onClick={forgotClicked}>
                    forgot password
                  </Button>
                </div>
              </>
            )}
            {passwordRequired !== null && (
              <>
                <p className="text-center">New password required.</p>
                <Form.Group className="mb-4" controlId="passwordGroup">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" value={newPassword} onChange={handleNewPasswordChange} className="rounded-pill" />
                </Form.Group>
                <Alert variant="danger" hidden={error === ''}>
                  {error}
                </Alert>
                <div className="text-center">
                  <Button variant="primary" type="submit" className="rounded-pill">
                    Update password
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Container>
        <Footer />
      </Container>
    </>
  );
}
