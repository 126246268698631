import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import AuthHeader from '../components/site-header';
import Footer from '../components/footer';
import { Device } from '../types/device';
import LeftNav from '../components/left-nav';
import { Api } from '../services/api';
import { AlertResponse } from '../types/alerts';
import { DeviceContext } from '../context/device-context';

export default function Alerts() {
  const api = Api.getInstance();
  const deviceContext = useContext(DeviceContext);
  const [devices, setDevices] = useState<Array<Device> | null>(null);
  const [alerts, setAlerts] = useState<AlertResponse | null>(null);
  const [pageItems, setPageItems] = useState<Array<number> | null>(null);
  const [siteName, setSiteName] = useState<string | null>(null);
  const { robot } = useParams();

  function timestampToString(timestamp: number): string {
    const dt = new Date(timestamp * 1000);
    return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function setup() {
    try {
      const devices: Array<Device> = deviceContext.devices;

      if (robot !== undefined) {
        const device = deviceContext.getDevice(robot);
        if (device !== undefined) {
          setSiteName(device.deviceName);
        }
      }
      setDevices(devices);
    } catch (error) {
      console.error('Error occurred getting location', error);
    }
  }

  async function getAlerts(page = 1, count = 10) {
    if (robot === undefined) return;
    try {
      const alertResponse = await api.getAlerts(robot, page, count);
      alertResponse.data.forEach((alert) => {
        alert.confidence = Math.round(alert.confidence * 100);
      });
      setAlerts(alertResponse);
      const pageItems = [];
      const pages = Math.ceil(alertResponse.metadata.total / alertResponse.metadata.count);
      for (let number = 1; number <= pages; number++) {
        pageItems.push(number);
      }
      setPageItems(pageItems);
    } catch (error) {
      console.error('Error occurred fetching alerts', error);
    }
  }

  useEffect(() => {
    setup();
    if (alerts === null) {
      getAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceContext]);

  return (
    <Container as="main" className="App py-4 px-3 mx-auto" fluid>
      <AuthHeader />
      <Row>
        <Col lg={2}>{devices !== null && <LeftNav devices={devices} />}</Col>
        <Col lg={10}>
          <h3>ALERTS FOR {siteName ?? robot?.toUpperCase()}</h3>
          <div className="d-flex align-content-start flex-wrap">
            {alerts !== null && alerts.data.length === 0 && <p>There are no alerts for this location</p>}
            {alerts?.data.map((alert) => (
              <Card key={alert._id} style={{ width: '450px', marginRight: '5px', marginBottom: '5px' }}>
                <Card.Img variant="top" src={alert.url} title="Alert Image"></Card.Img>
                <Card.Body>
                  <Card.Title>{alert.incident_type}</Card.Title>
                  <ul>
                    <li>Severity: {alert.severity}</li>
                    <li>Date: {timestampToString(alert.timestamp)}</li>
                    <li>Site: {alert.site_id}</li>
                    <li>Confidence: {alert.confidence}%</li>
                  </ul>
                </Card.Body>
              </Card>
            ))}
          </div>
          <Pagination>
            {alerts !== null &&
              pageItems?.map((number) => (
                <Pagination.Item key={number} active={number === alerts.metadata.page} onClick={() => getAlerts(number)}>
                  {number}
                </Pagination.Item>
              ))}
          </Pagination>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
