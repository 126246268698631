import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

export class NewPasswordRequired extends Error {
  public readonly userAttributes: any;
  public readonly requiredAttributes: any;
  public readonly user: CognitoUser;
  constructor(user: CognitoUser, userAttributes: any, requiredAttributes: any) {
    super();
    this.user = user;
    this.userAttributes = userAttributes;
    this.requiredAttributes = requiredAttributes;
  }
}

export class CognitoService {
  private userPool: CognitoUserPool;
  private currentUser: CognitoUser | null;

  constructor(userPoolId: string, clientId: string) {
    this.userPool = new CognitoUserPool({ UserPoolId: userPoolId, ClientId: clientId });
    this.currentUser = this.userPool.getCurrentUser();
  }

  getCognitoUser(username: string) {
    return new CognitoUser({ Username: username, Pool: this.userPool });
  }

  async getSession(): Promise<CognitoUserSession | null> {
    let currentUser = this.currentUser;
    if (this.currentUser === null) {
      currentUser = this.userPool.getCurrentUser();
      this.currentUser = currentUser;
    }
    return new Promise<CognitoUserSession | null>(function (resolve, reject) {
      if (currentUser === null) {
        return reject('No Current User');
      }
      currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  async login(username: string, password: string): Promise<CognitoUserSession> {
    const currentUser = this.getCognitoUser(username);
    this.currentUser = currentUser;
    return new Promise(function (resolve, reject) {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      currentUser.authenticateUser(authenticationDetails, {
        onSuccess: function (res: CognitoUserSession) {
          resolve(res);
        },
        onFailure: function (err: Error) {
          reject(err);
        },
        newPasswordRequired: function (userAttributes: any, requiredAttributes: any) {
          reject(new NewPasswordRequired(currentUser, userAttributes, requiredAttributes));
        },
      });
    });
  }

  async logout() {
    if (this.currentUser !== null) {
      const currentUser = this.currentUser;
      return new Promise<void>(function (resolve, reject) {
        currentUser.signOut(() => {
          resolve();
        });
      });
    }
  }

  async getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
    let currentUser = this.currentUser;
    if (this.currentUser === null) {
      currentUser = this.userPool.getCurrentUser();
      this.currentUser = currentUser;
    }
    return new Promise(function (resolve, reject) {
      if (currentUser === null) {
        return reject('No Current User');
      }
      currentUser.getUserAttributes(function (err?: Error, attributes?: CognitoUserAttribute[]) {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    });
  }

  async setAttribute(attribute: ICognitoUserAttributeData): Promise<void> {
    let currentUser = this.currentUser;
    if (this.currentUser === null) {
      currentUser = this.userPool.getCurrentUser();
      this.currentUser = currentUser;
    }
    return new Promise(function (resolve, reject) {
      if (currentUser === null) {
        return reject('No Current User');
      }
      const attributeList = [];
      const res = new CognitoUserAttribute(attribute);
      attributeList.push(res);

      currentUser.updateAttributes(attributeList, (err?: Error, _res?: string, _details?: any) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  async sendCode(username: string): Promise<any> {
    const cognitoUser = this.getCognitoUser(username);
    return new Promise(function (resolve, reject) {
      if (!cognitoUser) {
        return reject(`${username} not found`);
      }
      cognitoUser.forgotPassword({
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  async verifyCode(username: string, code: string): Promise<any> {
    const cognitoUser = this.getCognitoUser(username);
    return new Promise(function (resolve, reject) {
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  async forgotPassword(username: string, code: string, password: string): Promise<string> {
    const cognitoUser = this.getCognitoUser(username);
    return new Promise(function (resolve, reject) {
      if (!cognitoUser) {
        return reject(`${username} not found`);
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve('password updated');
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<string | undefined> {
    let currentUser = this.currentUser;
    if (this.currentUser === null) {
      currentUser = this.userPool.getCurrentUser();
      this.currentUser = currentUser;
    }
    return new Promise(function (resolve, reject) {
      if (currentUser === null) {
        return reject(new Error('No Current User'));
      }
      currentUser.changePassword(oldPassword, newPassword, function (err?: Error, res?: string) {
        if (err !== undefined) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }
}
