import Container from 'react-bootstrap/Container';
import CameraView from './camera/camera-view';
import { Camera } from '../types/camera';

export interface CamerasViewProps {
  cameras: Array<Camera>;
}

export default function CamerasView({ cameras }: CamerasViewProps) {
  return (
    <>
      <Container>
        <div className="row">
          <h3>Live View</h3>
          <div className="d-flex align-content-start flex-wrap">
            {cameras.map((camera) => (
              <CameraView key={camera.id} camera={camera} />
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}
