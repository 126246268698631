import Container from 'react-bootstrap/Container';
import { Device } from '../types/device';

export interface LeftNavProps {
  devices: Array<Device>;
}

export default function LeftNav({ devices }: LeftNavProps) {
  return (
    <>
      <Container>
        <ul className="list-unstyled">
          {devices?.map((device, index) => (
            <li key={index}>
              <a href={`#${index}Toggle`} data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls={`${index}Toggle`} className="btn-toggle">
                Location {device.deviceName}
              </a>
              <ul id={`${index}Toggle`} className="collapse.show submenu">
                <li>
                  <a href={`/liveview/${device.name}`}>Live View</a>
                </li>
                <li>
                  <a href={`/alerts/${device.name}`}>Alerts</a>
                </li>
                <li>
                  <a href={`/overview/${device.name}`}>Location Overview</a>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
}
