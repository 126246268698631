import { useState, useEffect } from 'react';

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  useEffect(() => {
    // const passwordSchema = yup.object().shape({
    //   password: yup.string().min(8).required(),
    // })

    // if (password.length === 0) {
    //   setPasswordIsValid(true)
    //   return
    // }

    // const isValid = passwordSchema.isValidSync({ password })

    setPasswordIsValid(true);
  }, [password]);

  return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue);
  const [usernameIsValid, setUsernameIsValid] = useState(true);

  useEffect(() => {
    // const usernameSchema = yup.object().shape({
    //   username: yup.string().min(8).required(),
    // })

    // if (username.length === 0) {
    //   setUsernameIsValid(true)
    //   return
    // }

    // const isValid = usernameSchema.isValidSync({ username })

    setUsernameIsValid(true);
  }, [username]);

  return { username, setUsername, usernameIsValid };
};
