import Header from './components/header';
import Footer from './components/footer';
import Container from 'react-bootstrap/Container';

export default function ErrorPage() {
  return (
    <>
      <Container as="main" className="py-4 px-3 mx-auto">
        <Header />
        <Container>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
        </Container>
        <Footer />
      </Container>
    </>
  );
}
