import { useRef, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import mapboxgl, { Map, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import AuthHeader from '../components/site-header';
import Footer from '../components/footer';
import { Location } from '../types/location';
import spinner from '../assets/spinner.svg';
import { Device } from '../types/device';
import LeftNav from '../components/left-nav';
import { Api } from '../services/api';
import { DeviceContext } from '../context/device-context';

// Had to add the `any` due to a bug in the mapbox-gl typescript definition.
(mapboxgl as any).accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // eslint-disable-line @typescript-eslint/no-explicit-any

export default function Overview() {
  const api = Api.getInstance();
  const deviceContext = useContext(DeviceContext);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(13);
  const [location, setLocation] = useState<Location | null>(null);
  const [devices, setDevices] = useState<Array<Device> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [siteName, setSiteName] = useState<string | null>(null);

  const { robot } = useParams();

  async function setup() {
    try {
      const devices: Array<Device> = deviceContext.devices;
      setDevices(devices);
      if (robot !== undefined) {
        const device = deviceContext.getDevice(robot);
        if (device !== undefined) {
          setSiteName(device.deviceName);
        }
      }
    } catch (error) {
      console.error('Error occurred getting location', error);
    }
  }

  async function getTelemetry(robotName: string) {
    try {
      if (location === null) {
        const loc = await api.getTelemetry(robotName);
        const device = deviceContext.getDevice(robotName);
        if (device !== undefined) {
          device.location = loc;
        }
        setLng(loc.gps_lon);
        setLat(loc.gps_lat);
        setLocation(loc);
      }
    } catch (error) {
      console.error('Error occurred getting location', error);
      setError((error as Error).message);
    }
  }

  useEffect(() => {
    setup();

    if (robot !== undefined && location === null) {
      getTelemetry(robot);
    }

    if (map.current !== null || location === null) return; // initialize map only once
    map.current = new Map({
      container: mapContainer.current ?? 'map',
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom,
    });
    new Marker().setLngLat({ lng, lat }).addTo(map.current);
    map.current?.on('move', () => {
      if (map.current === null) return;
      const curr = map.current;
      setLng(parseFloat(curr.getCenter().lng.toFixed(4)));
      setLat(parseFloat(curr.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(curr.getZoom().toFixed(2)));
    });
  }, [deviceContext, location]);

  return (
    <Container as="main" className="App py-4 px-3 mx-auto" fluid>
      <AuthHeader />
      <Row>
        <Col lg={2}>{devices !== null && <LeftNav devices={devices} />}</Col>
        <Col lg={10}>
          <h3>LOCATION {siteName ?? robot?.toUpperCase()} OVERVIEW</h3>
          {error !== null && (
            <Container>
              <p>Unable to find geolocation data for this location.</p>
            </Container>
          )}
          {error === null && (
            <Card id={robot}>
              <div ref={mapContainer} style={{ width: '100%', height: '800px' }} className="text-center">
                {location === null && <img className="spinner" src={spinner} alt="loading" style={{ marginTop: '20vmin' }} />}
              </div>
              <Card.Body>
                <Card.Text>
                  Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
